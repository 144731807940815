<ng-container *ngIf="systemStatus">
  <app-tabs-picker-toolbar>
    <button mat-stroked-button (click)="fetchSystemStatus()">
      <app-config-icon iconIdentifier="refresh"></app-config-icon>
      {{ 'refresh' | translate }}
    </button>
  </app-tabs-picker-toolbar>
  <div class="summary">
    <div class="summary-entries">
      <div class="entry">
        <mat-label class="left">System status:</mat-label>
        <span class="right">{{ systemStatus.status }}</span>
      </div>
      <div class="entry">
        <mat-label class="left">Timestamp:</mat-label>
        <span class="right">{{ systemStatus.timestamp }}</span>
      </div>
    </div>
    <div class="description">
      <ul>
        <li>OPERATIONAL - All resources are operational</li>
        <li>DEGRADED - Some resources are not operational</li>
        <li>CRITICAL - All required resources are not operational</li>
        <li>JVM resources - OPERATIONAL if at least 20% of resources are available</li>
      </ul>
    </div>
  </div>

  <app-grid
    tableId="system-status-table"
    [rowData]="rowData"
    [columnDefs]="columns"
    [gridOptions]="gridOptions"
    [noGridFilterTopPadding]="true"
    ></app-grid>
</ng-container>
