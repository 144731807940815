<div class="grid-filter-wrapper">
  <div class="search-wrapper">
    <mat-form-field appearance="outline" subscriptSizing="dynamic" *ngIf="showSearch" class="search">
      <input #searchInput matInput placeholder="{{(searchPlaceholder || 'search') | translate}}"
             matTooltip="{{searchPlaceholder | translate}}"
             (focusin)="searchInputFocused = true" (focusout)="searchInputFocused = false">
      <app-config-icon *ngIf="!searchInputFocused" matPrefix iconIdentifier="search"></app-config-icon>
    </mat-form-field>
  </div>
  <div class="grid-filter" *ngIf="anyGridFilterEnabled" [class.grid-filter-border]="activeFilters.length > 0">
    <div class="filter-chips-wrapper" (click)="openGridFilterDialog()">
      <div *ngIf="activeFilters.length === 0" class="href-link placeholder body-3">
        <div>{{ 'advancedSearch' | translate }}</div>
      </div>
      <div *ngIf="activeFilters.length > 0" class="active-filters">
        <mat-chip-set class="nowrap">
          <mat-chip *ngFor="let filter of shownFilters" (removed)="clearFilter(filter)">
            <div class="filter-text color-white">{{ filter.value }}</div>
            <button matChipRemove [matTooltip]="'clearFilter' | translate">
              <app-config-icon
                iconIdentifier="close"
                class="h2 color-white"
              />
            </button>
          </mat-chip>
        </mat-chip-set>
      </div>
      <div *ngIf="activeFilters.length > 2" class="overflow-box body-2">
        + {{ activeFilters.length - 2 }} {{ 'more' | translate }}...
      </div>
    </div>
    <app-config-icon
      iconIdentifier="filter_alt"
      (click)="openGridFilterDialog()"
    ></app-config-icon>
  </div>
  <div class="icon-wrapper" *ngIf="sidebarEnabled">
    <app-config-icon
      iconIdentifier="view_column"
      (click)="toggleColumnsMenu()"
    ></app-config-icon>
  </div>
</div>
