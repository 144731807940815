<button mat-stroked-button (click)="addExternalLink()">
  <app-config-icon iconIdentifier="open_in_new"></app-config-icon>
  {{ 'externalLinkAdd' | translate }}
</button>
<app-grid
  tableId="external-links"
  [columnDefs]="linkColumns"
  [gridOptions]="linkGridOptions"
  [rowData]="externalLinks"
  [pagination]="false"
  [noGridFilterTopPadding]="true"
></app-grid>
